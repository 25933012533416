export const mockProducts = [
  {
    id: 100,
    product_name: 'Advanced Génifique Radiance Boosting Face Serum',
    store_name: 'Lancôme',
    price: 89.0,
    favorite: true,
    cover_medias: [
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/lancome_face_serum_1.webp',
      'https://cdn.midjourney.com/a7c9384e-5cba-4a0e-ba3a-197b077bbd97/0_0.png',
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/chance_eau_tendre_eau_de_toilette_1.webp',
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/sk_ii_facial_treatment_essence_1.webp',
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/mba13_m2_midnight_1.jpeg',
      'https://cdn.midjourney.com/fffea7bc-e5d6-4295-83b3-05462fd3bb4c/0_0.png',
    ],
    hasVariant: true,
  },
  {
    id: 101,
    product_name: 'CHANCE EAU TENDRE Eau de Toilette',
    store_name: 'Chanel',
    price: 93.0,
    favorite: true,
    cover_medias: [
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/chance_eau_tendre_eau_de_toilette_1.webp',
    ],
  },
  {
    id: 102,
    product_name: 'Anti-Aging Facial Treatment Essence',
    store_name: 'SK-II',
    price: 190.0,
    favorite: false,
    cover_medias: [
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/sk_ii_facial_treatment_essence_1.webp',
    ],
  },
  {
    id: 103,
    product_name:
      'Apple Watch Series 9 GPS 45mm with M/L Sport Band and Aluminum Case - Midnight Blue',
    store_name: 'Apple',
    price: 188.0,
    favorite: false,
    cover_medias: [
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/apple_watch_9_blue.webp',
    ],
  },
  {
    id: 104,
    product_name: 'MacBook Air laptop (13-inch) - Apple M2 chip',
    store_name: 'Apple',
    price: 899.0,
    favorite: false,
    cover_medias: [
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/mba13_m2_midnight_1.jpeg',
    ],
  },
  {
    id: 105,
    product_name: 'Eye Color Quad - Refill',
    store_name: 'Cle de Peau Beaute',
    price: 75.0,
    favorite: false,
    cover_medias: [
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/cle_de_peau_eye_color_quad.webp',
    ],
  },
  {
    id: 106,
    product_name: 'The Moisturizing Soft Cream',
    store_name: 'La Mer',
    price: 75.0,
    favorite: false,
    cover_medias: [
      'https://ammall-seller-mock-1.s3.amazonaws.com/product_image/moisturizing_soft_cream.webp',
    ],
  },
  {
    id: 10001,
    product_name:
      "Levi's Men's Slim Fit Cotton Blend Stretch Chinos, Modern Classic Fit, Zip Fly with Button Closure, Multiple Pocket Configuration, Versatile for Casual or Business Casual Wear",
    store_name: "Levi's",
    price: 79.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/3afd557e-5a74-4c4e-bb71-66a6af927232/0_0.png',
    ],
  },
  {
    id: 10002,
    product_name:
      "Hanes Men's Henley Neck Cotton T-Shirt, Short Sleeve, Relaxed Fit, Ribbed Knit Collar, Side Seam Construction, Perfect for Layering or Solo Wear",
    store_name: 'Hanes',
    price: 24.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/329a8f1a-3cb8-4419-9b35-d25edc6974e4/0_0.png',
    ],
  },
  {
    id: 10003,
    product_name:
      "Columbia Men's Waterproof Windbreaker Jacket, Lightweight, Packable Design, Adjustable Hood, Zipper Closure, Multiple Pockets, Ideal for Outdoor Activities",
    store_name: 'Columbia',
    price: 69.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/fffea7bc-e5d6-4295-83b3-05462fd3bb4c/0_0.png',
    ],
  },
  {
    id: 10004,
    product_name:
      "Fossil Men's Leather Belt, Genuine Leather, Adjustable Buckle Closure, Classic Design, Suitable for Casual or Formal Wear",
    store_name: 'Fossil',
    price: 39.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/ff00dcac-a4dc-4687-8540-68e9d1fe4954/0_0.png',
    ],
  },
  {
    id: 10005,
    product_name:
      "Nike Men's Athletic Sneakers, Breathable Mesh Upper, Cushioned Insole, Rubber Outsole, Lace-Up Closure, Perfect for Running, Gym, or Casual Wear",
    store_name: 'Nike',
    price: 79.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/f684f038-1039-4b2e-82a0-fe0e3d1e5959/0_0.png',
    ],
  },
  {
    id: 10006,
    product_name:
      "Calvin Klein Men's Wool Blend Overcoat, Classic Fit, Double-Breasted Style, Notch Lapel, Multiple Pockets, Warm and Stylish for Winter",
    store_name: 'Calvin Klein',
    price: 299.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/f70a9381-c3c7-44d1-b469-21e36b40ab8f/0_0.png',
    ],
  },
  {
    id: 10007,
    product_name:
      "H&M Men's Slim Fit Denim Jeans, Distressed Wash, Stretch Fabric, Zip Fly, Five-Pocket Style, Modern and Comfortable",
    store_name: 'H&M',
    price: 74.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/a7c9384e-5cba-4a0e-ba3a-197b077bbd97/0_0.png',
    ],
  },
  {
    id: 10008,
    product_name:
      "Carhartt Men's Cotton Flannel Shirt, Plaid Pattern, Button-Down Collar, Chest Pocket, Regular Fit, Perfect for Layering",
    store_name: 'Carhartt',
    price: 49.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/07c32a32-6696-4701-9a57-9dbe622a6140/0_0.png',
    ],
  },
  {
    id: 10009,
    product_name:
      "Uniqlo Men's Merino Wool Crew Neck Sweater, Soft and Warm, Ribbed Cuffs and Hem, Classic Fit, Versatile for Casual or Dressy Occasions",
    store_name: 'Uniqlo',
    price: 129.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/68a386ed-25e3-4508-8356-599308577deb/0_0.png',
    ],
  },
  {
    id: 10010,
    product_name:
      "Ray-Ban Men's Sunglasses, Polarized Lenses, UV Protection, Lightweight Frame, Stylish Design, Perfect for Outdoor Activities",
    store_name: 'Ray-Ban',
    price: 99.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/7bf904d7-bfe3-4a00-a23d-f808079b04bd/0_0.png',
    ],
  },
  {
    id: 10011,
    product_name:
      "Patagonia Men's Regular Fit Organic Cotton T-Shirt, Crew Neck, Short Sleeve, Sustainable and Soft, Perfect for Everyday Wear",
    store_name: 'Patagonia',
    price: 22.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/8903126c-ce95-4b8d-9b5c-4e3cd0b4b813/0_0.png',
    ],
  },
  {
    id: 10012,
    product_name:
      'Hydrating Rose Facial Toner - Alcohol-free, pH-balanced, with hyaluronic acid for intense moisture, rosewater for calming and toning, suitable for all skin types',
    store_name: 'Bloom & Glow',
    price: 19.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/fcce5057-12c2-4a16-9426-8c87f9c02a0f/0_0.png',
    ],
  },
  {
    id: 10013,
    product_name:
      'Anti-Aging Retinol Serum with Hyaluronic Acid - Potent wrinkle-reducing formula, visibly reduces fine lines and wrinkles, improves skin texture and tone, vegan and cruelty-free',
    store_name: 'Rejuvenate Skin',
    price: 65.0,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/519d133b-c86d-4547-96dd-0f852d03f8fb/0_0.png',
    ],
  },
  {
    id: 10014,
    product_name:
      'Vitamin C Brightening Cream - Packed with antioxidants, evens skin tone, reduces dark spots, boosts collagen production, lightweight and non-greasy',
    store_name: 'Radiance Rituals',
    price: 34.95,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/dff69f1b-eb31-43cf-b128-ea7736a6c369/0_0.png',
    ],
  },
  {
    id: 10015,
    product_name:
      'Charcoal Deep Cleansing Mask - Detoxifies and purifies pores, removes impurities and excess oil, suitable for oily and acne-prone skin, with cooling menthol',
    store_name: 'Purify & Clear',
    price: 22.5,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/6906f5e2-fc13-4b01-a85f-b5e81cfb6f17/0_0.png',
    ],
  },
  {
    id: 10016,
    product_name:
      'Nourishing Coconut Body Butter - Intensely moisturizes dry skin, rich in vitamins and minerals, absorbs quickly, leaves skin soft and silky, tropical fragrance',
    store_name: 'Tropical Bliss',
    price: 28.0,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/584cd46b-dfd6-402a-8dea-58fbc6cba3a7/0_0.png',
    ],
  },
  {
    id: 10017,
    product_name:
      'Acne Fighting Gel Cleanser - Salicylic acid-based, targets blemishes and prevents breakouts, oil-free and non-comedogenic, suitable for oily and acne-prone skin',
    store_name: 'Clear Complexion',
    price: 14.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/7a72246a-8293-4638-a5f8-4498047738a1/0_0.png',
    ],
  },
  {
    id: 10018,
    product_name:
      'Hyaluronic Acid Moisturizer - Plumps and hydrates skin, reduces fine lines, locks in moisture, suitable for all skin types, oil-free and non-comedogenic',
    store_name: 'Hydrate & Glow',
    price: 42.0,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/b5237bf6-a310-4193-bd15-610ea38a129d/0_0.png',
    ],
  },
  {
    id: 10019,
    product_name:
      'Exfoliating Sugar Scrub with Vitamin E - Gently removes dead skin cells, improves skin texture, leaves skin soft and smooth, suitable for all skin types',
    store_name: 'Smooth & Soft',
    price: 18.5,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/385ffe4b-3cb0-4bca-8db8-9d7dc18f8a78/0_0.png',
    ],
  },
  {
    id: 10020,
    product_name:
      'Caffeine-Infused Eye Cream - Reduces puffiness and dark circles, hydrates delicate eye area, with vitamin K for brightening, suitable for all skin types',
    store_name: 'Revitalize Eyes',
    price: 25.99,
    favorite: false,
    cover_medias: [
      'https://cdn.midjourney.com/469234f9-7dcd-40df-9481-c423c5a714e4/0_0.png',
    ],
  },
];
