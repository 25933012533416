import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

const initState = {
  loggedin: false,
};

export const AuthStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initState),
  withMethods((state) => ({
    login() {
      patchState(state, { loggedin: true });
    },
    logout() {
      patchState(state, { loggedin: false });
    },
  })),
);
