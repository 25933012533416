export const MATERIAL_ICON_NAME  =
{
  favorite: 'favorite',
  shoppingCart: 'shopping_cart',
  orders: 'orders',
  arrow_back: 'arrow_back',
  mail: 'mail',
  chevron_right: 'chevron_right',
  arrow_upward: 'arrow_upward',
  more_horiz: 'more_horiz',
  keyboard_arrow_down: 'keyboard_arrow_down',
  keyboard_arrow_up: 'keyboard_arrow_up'
}
