
export const APP_RESOURCE = {
  SMALL_LOGO: 'assets/image/logo/logo.png',
  LARGE_LOGO: 'assets/image/logo/logo_complete_horizontal.png',
}
export const APPCONSTANT = {
  ANONYMOUS: 'anonymous',
  DEFAULT_ROLE: 'buyer',
  DEFAULT_SELLER: 'seller',
  COOKIENAME: {
      LANGUAGE: 'lang',
  },
  LOCAL_STORAGE_ITEM_NAME: {
      METADATA: 'meta.',
      THREAD_AMOUNT: 'opened_thread',
      LOGIN_USER: 'loginUser',
      LOGIN_USER_NAME: 'loginName',
      JWT_TOKEN: 'JWT',
      MAIN_MENU: 'mainMenu',
      LOGIN_PAGE_URL: 'login',
      LAST_USED_URL: 'lastUsedUrl', // 用户最后一次访问服务器时使用的页面
      LAST_ACCESS_TIME: 'lastAccessTime', // 用户最后一次主动访问服务器的时间
  },
  DATE_FORMAT: 'yyyy-MM-dd',
  DATETIME_FORMAT: 'yyyy-MM-dd hh:mm:ss',
  URL_PARAMETER: {
      BACK_URL: 'backUrl', // 当前页面有back 或者 返回等回到上一页时，需要用到该url参数，以方便回到上一页
      REDIRECT_URL: 'redirectUrl', // 如果登录页面有此参数，在成功登录之后去该页面
  },
  NOT_IMPLEMENT: 'Not implemented functionality.',
  EMPTY_ACCOUNT_IMAGE: 'assets/image/empty_avatar.png',
  emptyImage: 'assets/image/empty_asset.png',
  NO_SEARCH_RESULT: 'assets/image/empty_search.png',
  feedbackCloseDelaySecond: 6,
  minCharsForSearching: 1,
  OneDayMilliSecond: 24 * 60 * 60 * 1000,
  PAGE_SIZE: 5,
  LOADMORE_SIZE: 10,                       // 使用‘load more’能加载数据的最大数量
  emailSendConfirmationData: {
      imageSrc: 'assets/image/mail.png',
      title: 'Reset link sent',
      description: 'We’ve sent a password reset link to your email',
      loginUserEmail: ''
  }
}

export const Amm_CARD_ISSUER = {
  BOA: 'Bank of American',
  CAPITAL: 'Capital One',
  UNKNOW: 'Unknow Finicial Organization',
}

export const Amm_CARD_TYPE = {
  VISA: 'visa',
  MASTER: 'mastercard',
  AMEX: 'amex',
  UNKNOW: 'unknow',
}


/**
 * Holds all ammall backend micro-service
 * name
 */
export const MODULE_SERVICE_NAME = {
  RBAC: 'rbac',
  CATALOG: 'catalog',
  SHOPPINGCART: 'shoppingcart',
  MERCHANT: 'merchant',
  BMS: 'bms',
  CRM: 'crm',
  GLOBALDATA: 'globaldata'
}
