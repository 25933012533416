import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  APPCONSTANT,
  endpointPath,
  MODULE_SERVICE_NAME,
  PolarJServerModelContextPath,
} from '../constants';
import { UseraccountModel } from '../models';
import { PolarJService } from './polarj.service';
import { StorageService } from './storage.service';

const RBAC_MODULE_NAME = 'rbac';

@Injectable({ providedIn: 'root' })
export class RbacService {
  private _polarjService: PolarJService = inject(PolarJService);
  private _router: Router = inject(Router);
  private _storageService: StorageService = inject(StorageService);

  private currentLoginUser: UseraccountModel | undefined = undefined;

  public saveUserAccount(ua: UseraccountModel): Observable<UseraccountModel> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: ua.id ? '' + ua.id : '',
      para: ua,
      singleData: true,
    });
  }

  public changeUserAccountPassword(
    loginName: string,
    oldPswd: string,
    newPswd: string
  ): Observable<any> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: endpointPath.CHANGE_PASSWORD,
      para: {
        loginName: loginName,
        oldPassword: oldPswd,
        newPassword: newPswd,
      },
      enableErrorMsg: true,
      singleData: true,
    });
  }

  public currentLogin(): Observable<UseraccountModel | undefined> {
    if (!this.isLogged()) {
      return of(undefined);
    }
    const u: UseraccountModel = this._storageService.getSessionItem(
      APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER
    );
    if (u != null) {
      return of(u);
    }
    return this._polarjService
      .getCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.AUTH,
        requestMappingString: endpointPath.LOGIN,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        tap((v) => {
          if (v == null || v.anonUser) {
            this.removeLocalCredential();
            this.removeLocalMeta();
          } else {
            this.saveCredentialLocally(v);
          }
        })
      );
  }

  public login(
    email:string,
    password: string,
    rememberMe?: boolean,
   
  ): Observable<UseraccountModel | null> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.AUTH,
        requestMappingString: endpointPath.LOGIN,
        para: {loginName:email,password:password},
     
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        map((u: UseraccountModel) =>
          this._afterLogin(u, rememberMe)
        )
      );
  }
  public checkEmail(email:string): Observable<any> {
    // console.log(RBAC_MODULE_NAME,PolarJServerModelContextPath.USERACCOUNT)
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.USERACCOUNT,
        requestMappingString: endpointPath.EXISTS,
        para: email,
        enableErrorMsg: true,
        singleData: true,
      });
      
  }

  public emailCode(useracc: UseraccountModel): Observable<boolean> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.USERACCOUNT,
        requestMappingString: endpointPath.RESET_PASSWORD_REQUEST,
        para: useracc,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        map((u) => {
          return u;
        })
      );
  }
  public verifyCode(useracc: UseraccountModel): Observable<boolean> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.USERACCOUNT,
        requestMappingString: endpointPath.VERIFY_CODE,
        para: useracc,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        map((u) => {
          return u;
        })
      );
  }

  public emailLogin(oneTimeToken: string): Observable<UseraccountModel | null> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.AUTH,
        requestMappingString: endpointPath.EMAIL_LOGIN,
        para: oneTimeToken,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(map((u: UseraccountModel) => this._afterLogin(u)));
  }

  public googleLogin(googleToken: string): Observable<UseraccountModel | null> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.AUTH,
        requestMappingString: endpointPath.SOCIALLOGIN+'/'+endpointPath.GOOGLE,
        urlPara: 'role=buyer' ,
        para: googleToken,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(map((u: UseraccountModel) => this._afterLogin(u)));
  }

  public registerV2(
    useracc: UseraccountModel,
    returnUrl: string
  ): Observable<any> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: endpointPath.REGISTER,
      urlPara: 'returnUrl=' + returnUrl,
      para: useracc,
      enableErrorMsg: true,
      singleData: true,
    });
  }

  public register(
    useracc: UseraccountModel,
    captchaResp: string,
    returnUrl: string
  ): Observable<boolean> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.USERACCOUNT,
        captchaResp: captchaResp,
        requestMappingString: endpointPath.REGISTER,
        urlPara: 'returnUrl=' + returnUrl,
        para: useracc,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        map((u) => {
          return u != null;
        })
      );
  }
  public registerStart(user:UseraccountModel): Observable<boolean> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.USERACCOUNT,
        requestMappingString: endpointPath.REGISTER,
        para:user,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        map((u) => {
          return u != null;
        })
      );
  }
  public registerComplete(loginName: string,verificationCode: string,): Observable<boolean> {
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.USERACCOUNT,
        requestMappingString: endpointPath.CONFIRM,
        urlPara: 'loginName=' + loginName+'&verificationCode='+verificationCode,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        map((u) => {
          return u != null;
        })
      );
  }
  public codeVerify(
    loginName: string,
    verificationCode: string,
  
  ): Observable<boolean> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: endpointPath.VERIFY_CODE,
      para:{loginName:loginName,verificationCode:verificationCode},
      enableErrorMsg: true,
      singleData: true,
    });
  }

  public requestVerificationCode(
    loginName: string,
    returnUrl: string
  ): Observable<UseraccountModel> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: endpointPath.RESEND_VERIFICATION,
      urlPara: 'loginName=' + loginName + '&returnUrl=' + returnUrl,
      enableErrorMsg: true,
      singleData: true,
    });
  }

  public isLogged(): boolean {
    const jwt: string = this._storageService.getJWT();

    if (jwt && jwt.length > 0) {
      return true;
    }
    this.removeLocalMeta();
    return false;
  }

  public logout(): Observable<any> {
    // 在登出之前，先保存登录用户最后的访问链接
    this.saveCurrentUrl();
    return this._polarjService
      .postCall({
        moduleServiceName: RBAC_MODULE_NAME,
        modelName: PolarJServerModelContextPath.AUTH,
        requestMappingString: endpointPath.LOGOUT,
        enableErrorMsg: true,
        singleData: true,
      })
      .pipe(
        tap(() => {
          this.removeLocalCredential();
          this.removeLocalMeta();
          if (!environment.enableAnonymous) {
            this._router.navigateByUrl(
              APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_PAGE_URL
            );
          }
        })
      );
  }

  public requestOneTimeLoginLink(
    loginEmail: string,
    captchaResp: string,
    returnUrl: string
  ): Observable<boolean> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.AUTH,
      requestMappingString: endpointPath.EMAIL_LOGIN_REQUEST,
      urlPara: 'returnUrl=' + returnUrl,
      captchaResp: captchaResp,
      para: loginEmail,
      singleData: true,
    });
  }

  public requestResetPasswordLinkByEmail(
    loginEmail: string,
    captchaResp: string,
    returnUrl: string
  ): Observable<any> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: endpointPath.RESET_PASSWORD_REQUEST,
      urlPara: 'returnUrl=' + returnUrl,
      captchaResp: captchaResp,
      para: { loginName: loginEmail },
      enableErrorMsg: true,
      singleData: true,
    });
  }

  public resetPassword(
    loginName: string,
    verificationCode: string,
    newPassword: string
  ): Observable<boolean> {
    return this._polarjService.postCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: endpointPath.RESET_PASSWORD,
      para: {
        loginName: loginName,
        verificationCode: verificationCode,
        newPassword: newPassword,
      },
      enableErrorMsg: true,
      singleData: true,
    });
  }

  public getAccountProfilePicture(
    buyerId: number,
    fileName: string
  ): Observable<string> {
    return this._polarjService.getCall({
      moduleServiceName: RBAC_MODULE_NAME,
      modelName: PolarJServerModelContextPath.USERACCOUNT,
      requestMappingString: 'getImgUrl',
      urlPara: 'fileName=' + fileName + '&id=' + buyerId,
      singleData: true,
    });
  }
  private saveCurrentUrl(): void {
    const u = this.currentLoginUser;
    if (u != null && u.loginName != null) {
      this._storageService.setLocalItem(
        u.loginName + '-' + APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LAST_USED_URL,
        this._router.url
      );
    }
  }
  private saveCredentialLocally(userAccount: UseraccountModel): void {
    this._storageService.setSessionItem(
      APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER,
      userAccount
    );
    if (userAccount.jwtToken) {
      this._storageService.setJWT(userAccount.jwtToken);
    }
  }
  private removeLocalCredential(): void {
    this._storageService.removeSessionItem(
      APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER
    );
    this._storageService.removeSessionItem(
      APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.MAIN_MENU
    );
    this._storageService.removeJWT();
  }

  private removeLocalMeta(): void {
    for (let moduleName in MODULE_SERVICE_NAME) {
      this._storageService.removeLocalItem(
        APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.METADATA + moduleName.toLowerCase()
      );
    }
  }
  // 处理登录成功之后的操作。
  // 各个项目可以定义专用的处理。
  private _afterLogin(
    u: UseraccountModel,
    rememberMe?: boolean,
    urlAfterLogin?: string
  ): UseraccountModel | null {
    if (
      u == null ||
      u.jwtToken == null ||
      u.jwtToken.length == 0 ||
      u.loginName == null ||
      u.loginName.length == 0
    ) {
      return null;
    }
    if (rememberMe) {
      this._storageService.setLocalItem(
        APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LOGIN_USER_NAME,
        u.loginName
      );
    }
    if (urlAfterLogin && urlAfterLogin.length > 0) {
      this._storageService.setLocalItem(
        u.loginName + '-' + APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LAST_USED_URL,
        urlAfterLogin
      );
    }
    this.saveCredentialLocally(u);
    this.currentLoginUser = u;
    return u;
  }
}
