import moment from 'moment';

export const ordersMock = [
  {
    orderNumber: 'PO241231222500048',
    datePlaced: moment().add(-7, 'd'),
    orderTotal: 5009.56,
    shipTo: 'Truman Hong',
    storeLogo: '../../../assets/img/mock/orderhistory/AppleLogo.png',
    storeName: 'Apple',
    orderGroup: [
      {
        orderStatus: 'Order Received',
        orderItems: [
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/MacBookPro.png',
            productDescription:
              'Apple MacBook Pro - 16-inch Retina display, Apple M3 Pro chip, 48GB unified memory, 512GB SSD, macOS Monterey, ultra-thin and lightweight, powerful performance',
            variantInfo:
              'English|512GB Storage|18GB Unified Memory|Silver | M3 Pro chip with 12-core CPU, 18-core GPU',
            qty: 1,
          },
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Apple iPad Pro 12.1.png',
            productDescription:
              'Apple iPad Pro 12.9-inch - Liquid Retina XDR display, M2 chip, 128GB storage, Wi-Fi, advanced camera system, ProMotion technology',
            variantInfo: 'Wi-Fi|128GB|Space Gray',
            qty: 2,
          },
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Apple Magic Keyboard.png',
            productDescription:
              'Apple Magic Keyboard with Touch ID for Mac - Numeric keypad, backlit keys, USB-C, seamless integration',
            variantInfo: 'English',
            qty: 1,
          },
        ],
      },
    ],
  },
  {
    orderNumber: 'PO241231223300044',
    datePlaced: moment().add(-20, 'd'),
    orderTotal: 570.96,
    shipTo: 'Truman Hong',
    storeLogo: '../../../assets/img/mock/orderhistory/Patagonia.png',
    storeName: 'Patagonia',
    orderGroup: [
      {
        orderStatus: 'Order Received',
        orderItems: [
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              "../../../assets/img/mock/orderhistory/Patagonia Men's Swim.png",
            productDescription:
              "Patagonia Men's Swim Trunks, Quick-Drying Fabric, Mesh Lining, Drawstring Waist, Side Pockets",
            variantInfo: 'Floral Summer|L',
            qty: 1,
          },
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              "../../../assets/img/mock/orderhistory/Patagonia Men's Regular Fit.png",
            productDescription:
              "Patagonia Men's Regular Fit Organic Cotton T-Shirt, Crew Neck, Short Sleeve, Sustainable and Soft, Perfect for Everyday Wear",
            variantInfo: 'Olive Green|M',
            qty: 3,
          },
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              "../../../assets/img/mock/orderhistory/Carhartt Men's Flanne.png",
            productDescription:
              "Carhartt Men's Flannel Lined Denim Jacket, Sherpa Collar, Multiple Pockets, Distressed Wash, Perfect for Cold Weather",
            variantInfo: 'MED|Beech',
            qty: 1,
          },
        ],
      },
    ],
  },
  {
    orderNumber: 'PO241231210300089',
    datePlaced: moment().add(-180, 'd'),
    orderTotal: 1669.53,
    shipTo: 'Truman Hong',
    storeLogo: '../../../assets/img/mock/orderhistory/logitech.png',
    storeName: 'Logitech',
    orderGroup: [
      {
        orderStatus: 'Shipped',
        carrier: '',
        trackingNumber: '',
        carrierTrackUrl: '',
        orderItems: [
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Logitech G502.png',
            productDescription:
              'Logitech G502 HERO High-Performance Wired Gaming Mouse - HERO 25K sensor, Lightsync RGB, 11 programmable buttons, adjustable weight, ergonomic design',
            variantInfo: '',
            qty: 1,
          },
        ],
      },
      {
        orderStatus: 'Preparing to Ship',
        carrier: '',
        carrierTrackUrl: '',
        orderItems: [
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Logitech G502.png',
            productDescription:
              'Logitech G502 HERO High-Performance Wired Gaming Mouse - HERO 25K sensor, Lightsync RGB, 11 programmable buttons, adjustable weight, ergonomic design',
            variantInfo: '',
            qty: 2,
          },
        ],
      },
    ],
  },
  {
    orderNumber: 'PO241231221900051',
    datePlaced: moment().add(-120, 'd'),
    orderTotal: 511.28,
    shipTo: 'Truman Hong',
    storeLogo: '../../../assets/img/mock/orderhistory/Bioglow.png',
    storeName: 'Bioglow',
    orderGroup: [
      {
        orderStatus: 'Shipped',
        orderItems: [
          {
            carrier: 'Fedex',
            trackingNumber: '312870945630',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Bioglow Moisturizing Body.png',
            productDescription:
              'Bioglow Moisturizing Body Lotion for Dry Skin, Ultra-Lightweight High-Oil Hydration, with Jojoba',
            variantInfo: '50ml|Acne Prone Cleanser',
            qty: 1,
          },
        ],
      },
      {
        orderStatus: 'Shipped',
        orderItems: [
          {
            carrier: 'Fedex',
            trackingNumber: '940165273018',
            carrierTrackUrl:
              'https://www.fedex.com/fedextrack?trknbr=940165273018',
            productImage:
              '../../../assets/img/mock/orderhistory/Vitamin C Brightening Cream.png',
            productDescription:
              'Vitamin C Brightening Cream - Packed with antioxidants, evens skin tone, reduces dark spots, boosts collagen production, lightweight and non-greasy',
            variantInfo: '',
            qty: 1,
          },
        ],
      },
      {
        orderStatus: 'Refund Issued',
        orderItems: [
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Anti-Aging Retinol Serum with Hyaluronic Acid.png',
            productDescription:
              'Anti-Aging Retinol Serum with Hyaluronic Acid - Potent wrinkle-reducing formula, visibly reduces fine lines and wrinkles, improves skin texture and tone, vegan and cruelty-free',
            variantInfo: '',
            qty: 1,
          },
        ],
      },
    ],
  },
  {
    orderNumber: 'PO24123122250004234',
    datePlaced: moment().add(-1, 'd'),
    orderTotal: 5009.56,
    shipTo: 'Truman Hong',
    storeLogo: '../../../assets/img/mock/orderhistory/AppleLogo.png',
    storeName: 'Apple',
    orderGroup: [
      {
        orderStatus: 'On Hold',
        orderItems: [
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/MacBookPro.png',
            productDescription:
              'Apple MacBook Pro - 16-inch Retina display, Apple M3 Pro chip, 48GB unified memory, 512GB SSD, macOS Monterey, ultra-thin and lightweight, powerful performance',
            variantInfo:
              'English|512GB Storage|18GB Unified Memory|Silver | M3 Pro chip with 12-core CPU, 18-core GPU',
            qty: 1,
          },
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Apple iPad Pro 12.1.png',
            productDescription:
              'Apple iPad Pro 12.9-inch - Liquid Retina XDR display, M2 chip, 128GB storage, Wi-Fi, advanced camera system, ProMotion technology',
            variantInfo: 'Wi-Fi|128GB|Space Gray',
            qty: 2,
          },
          {
            carrier: '',
            trackingNumber: '',
            carrierTrackUrl: '',
            productImage:
              '../../../assets/img/mock/orderhistory/Apple Magic Keyboard.png',
            productDescription:
              'Apple Magic Keyboard with Touch ID for Mac - Numeric keypad, backlit keys, USB-C, seamless integration',
            variantInfo: 'English',
            qty: 1,
          },
        ],
      },
    ],
  },
];

export const orderReceived = {
  orderNumber: 'PO241231222500048',
  placedDate: moment().add(-3, 'd').toDate(),
  cancellReason: 'Cancelled for no need',
  receiver: 'Truman Hong',
  shippingAddress: {
    line1: '12345 West Evergreen Terrace, Apartment 678',
    line2: '',
    line3: 'Springfield, Illinois 62704-5678',
    line4: 'United States',
  },
  phoneNumber: '+1 555 123-4567',
  store: {
    storeLogo: '../../../assets/img/mock/orderhistory/AppleLogo.png',
    storeName: 'Apple',
    storeId: 12,
  },
  tax: 283.56,
  shipping: {
    shippingFee: 0,
    shippingOption: 'Standard Shipping',
    deliveryDateRange: '7-15 business days',
  },
  orderGroup: [
    {
      orderStatus: 'Order Received',
      carrier: '',
      trackingNumber: '',
      carrierTrackUrl: '',
      orderItems: [
        {
          productImage: '../../../assets/img/mock/orderhistory/MacBookPro.png',
          productDescription:
            'Apple MacBook Pro - 16-inch Retina display, Apple M3 Pro chip, 48GB unified memory, 512GB SSD, macOS Monterey, ultra-thin and lightweight, powerful performance',
          variantInfo:
            'English|512GB Storage|18GB Unified Memory|Silver | M3 Pro chip with 12-core CPU, 18-core GPU',
          variants: {
            style: 'English',
            storage: '512GB Storage',
            memory: '18GB Unified Memory',
            color: 'Sliver',
            configuration: 'M3 Pro chip with 12-core CPU, 18-core GPU',
          },
          qty: 1,
          unitPrice: 2399,
        },
        {
          productImage:
            '../../../assets/img/mock/orderhistory/Apple iPad Pro 12.1.png',
          productDescription:
            'Apple iPad Pro 12.9-inch - Liquid Retina XDR display, M2 chip, 128GB storage, Wi-Fi, advanced camera system, ProMotion technology',
          variantInfo: 'Wi-Fi|128GB|Space Gray',
          variants: {
            style: 'English',
            storage: '128GB Storage',
            color: 'Space Gray',
            configuration: 'Wi-Fi',
          },
          qty: 2,
          unitPrice: 2198,
        },
        {
          productImage:
            '../../../assets/img/mock/orderhistory/Apple Magic Keyboard.png',
          productDescription:
            'Apple Magic Keyboard with Touch ID for Mac - Numeric keypad, backlit keys, USB-C, seamless integration',
          variantInfo: 'English',
          qty: 1,
          unitPrice: 129,
        },
      ],
    },
    {
      orderStatus: 'Preparing to Ship',
      carrier: '',
      trackingNumber: '',
      carrierTrackUrl: '',
      orderItems: [
        {
          productImage: '../../../assets/img/mock/orderhistory/MacBookPro.png',
          productDescription:
            'Apple MacBook Pro - 16-inch Retina display, Apple M3 Pro chip, 48GB unified memory, 512GB SSD, macOS Monterey, ultra-thin and lightweight, powerful performance',
          variantInfo:
            'English|512GB Storage|18GB Unified Memory|Silver | M3 Pro chip with 12-core CPU, 18-core GPU',
          qty: 1,
          unitPrice: 2399,
        },
        {
          productImage:
            '../../../assets/img/mock/orderhistory/Apple Magic Keyboard.png',
          productDescription:
            'Apple Magic Keyboard with Touch ID for Mac - Numeric keypad, backlit keys, USB-C, seamless integration',
          variantInfo: 'English',
          variants: {
            language: 'US English',
          },
          qty: 1,
          unitPrice: 129,
        },
      ],
    },
    {
      orderStatus: 'Shipped',
      carrier: 'Fedex',
      trackingNumber: '478123456789',
      carrierTrackUrl: 'asdklfasjdflak',
      orderItems: [
        {
          productImage: '../../../assets/img/mock/orderhistory/MacBookPro.png',
          productDescription:
            'Apple MacBook Pro - 16-inch Retina display, Apple M3 Pro chip, 48GB unified memory, 512GB SSD, macOS Monterey, ultra-thin and lightweight, powerful performance',
          variantInfo:
            'English|512GB Storage|18GB Unified Memory|Silver | M3 Pro chip with 12-core CPU, 18-core GPU',
          qty: 1,
          unitPrice: 2399,
        },
        {
          productImage:
            '../../../assets/img/mock/orderhistory/Apple Magic Keyboard.png',
          productDescription:
            'Apple Magic Keyboard with Touch ID for Mac - Numeric keypad, backlit keys, USB-C, seamless integration',
          variantInfo: 'English',
          variants: {
            language: 'US English',
          },
          qty: 1,
          unitPrice: 129,
        },
      ],
    },
  ],
  payment: {
    paymentMethodLogo: '../../../assets/img/mock/paymentMethods/logo-visa.png',
    lastFourBankCardNumber: '0887',
    billingAddress: {
      nameOnTheCard: 'Truman Hong',
      line1: '12345 West Evergreen Terrace, Apartment 678',
      line2: '',
      line3: 'Springfield, Illinois 62704-5678',
      line4: 'United States',
    },
    phoneNumber: '+1 555 123-4567',
  },
};
