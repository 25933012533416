import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, filter, map, of, tap } from 'rxjs';
import { StorageService } from './storage.service';
import { RestfulService } from './restful.service';
import { PagingData, ServerResponse, StatusInfo } from '../models';
import { HasStringValue } from '../../components.global';

@Injectable({ providedIn: 'root' })
export class PolarJService {
  private _restfulService: RestfulService = inject(RestfulService);
  private _router: Router = inject(Router);
  protected _storageService: StorageService = inject(StorageService);

  httpProcessDone(): Observable<boolean> {
    return this._restfulService.httpProcessing();
  }

  externalHttpProcessingBegin(): void {
    this._restfulService.externalHttpProcessingBegin();
  }

  externalHttpProcessingEnd(): void {
    this._restfulService.externalHttpProcessingEnd();
  }

  postCall(postCallPara: PostCallParameter): Observable<any> {
    const ob$ = this._restfulService.postCall(postCallPara, postCallPara.para);
    return this.handleServerResponse(
      ob$,
      postCallPara.enableErrorMsg,
      postCallPara.singleData,
      postCallPara.pageData
    );
  }

  deleteCall(delCallPara: DeleteCallParameter): Observable<any> {
    const ob$ = this._restfulService.deleteCall(delCallPara);
    return this.handleServerResponse(
      ob$,
      delCallPara.enableErrorMsg,
      delCallPara.singleData,
      delCallPara.pageData
    );
  }
  getCall(getCallPara: GetCallParameter): Observable<any> {
    const ob$ = this._restfulService.getCall(getCallPara);
    return this.handleServerResponse(
      ob$,
      getCallPara.enableErrorMsg,
      getCallPara.singleData,
      getCallPara.pageData
    );
  }

  downloadPostCall(
    postCallPara: PostCallParameter,
    para?: any,
    fileName?: string,
    fileType?: string
  ): void {
    this._restfulService.downloadPostCall(
      postCallPara,
      para,
      fileName || '',
      fileType || 'pdf',
      true
    );
  }

  downloadCall(
    getCallPara: GetCallParameter,
    fileName?: string,
    fileType?: string
  ): void {
    this._restfulService.downloadCall(
      getCallPara,
      fileName || '',
      fileType || 'pdf',
      true
    );
  }

  downloadFileCall(getCallPara: GetCallParameter): Observable<Blob> {
    return this._restfulService.downloadFileCall(getCallPara);
  }

  uploadCall(
    postCallPara: PostCallParameter,
    files: File[]
  ): Observable<number[]> {
    const ob$ = this._restfulService
      .uploadCall(postCallPara, files)
      .pipe(filter((res) => res.statusList != null));
    return this.handleServerResponse(ob$, postCallPara.enableErrorMsg);
  }

  private handleServerResponse(
    ob$: Observable<any>,
    enableErrorMsg?: boolean,
    oneData?: boolean,
    pageData?: boolean
  ): Observable<any> {
    if (oneData) {
      return ob$.pipe(
        map((response) => this.handleOneData(response, enableErrorMsg))
      );
    }
    if (pageData) {
      return ob$.pipe(
        map((response) => this.handlePagingData(response, enableErrorMsg))
      );
    }
    return ob$.pipe(
      map((response) => this.handleDataList(response, enableErrorMsg))
    );
  }

  private handleOneData(
    serverResponse: ServerResponse,
    enableErrorMsg?: boolean
  ): any {
    const hasError = this.hasError(serverResponse, enableErrorMsg);
    if (hasError) {
      return serverResponse.statusList || null;
    }
    if (!serverResponse.dataList) {
      return null;
    }
    if (serverResponse.dataList.length > 1) {
      console.error('singleData is true but returned more than one data.');
      return null;
    }
    return serverResponse.dataList[0];
  }

  private handlePagingData(
    serverResponse: ServerResponse,
    enableErrorMsg?: boolean
  ): PagingData<any> | null | Array<StatusInfo> {
    const hasError = this.hasError(serverResponse, enableErrorMsg);
    if (hasError) {
      console.error(serverResponse);
      return serverResponse.statusList || null;
    }
    const pagingData = new PagingData();
    pagingData.currentPageIndex = serverResponse.currentPageIndex;
    pagingData.dataList = serverResponse.dataList;
    pagingData.pageSize = serverResponse.pageSize;
    pagingData.totalPages = serverResponse.totalPages;
    pagingData.totalRecords = serverResponse.totalRecords;
    pagingData.groupCountsMap=serverResponse.groupCountsMap;
    return pagingData;
  }

  private handleDataList(
    serverResponse: ServerResponse,
    enableErrorMsg?: boolean
  ): Array<any> | null | Array<StatusInfo> {
    const hasError = this.hasError(serverResponse, enableErrorMsg);
    if (hasError) {
      console.error(serverResponse);
      return serverResponse.statusList || null;
    }
    if (!serverResponse.dataList) {
      return null;
    }
    return serverResponse.dataList;
  }

  private hasError(
    serverResponse: ServerResponse,
    enableErrorMsg?: boolean
  ): boolean {
    if (!serverResponse) {
      return true;
    }
    let hasError = false;
    if (
      serverResponse.statusList &&
      serverResponse.statusList.length > 0 &&
      enableErrorMsg
    ) {
      let msg = serverResponse.statusList[0].desc;
      serverResponse.statusList.forEach((status) => {
        if (status.error) {
          msg = status.desc;
          hasError = true;
        }
      });
      if (hasError) {
        // this._messageService.error(msg, enableErrorMsg);
        // console.log('ERROR: ' + msg);
        return true;
      }
      // console.log('ALERT: ' + msg);
      // this._messageService.alertWarning(msg, enableErrorMsg);
    }
    return false;
  }
  isLogged(): boolean {
    let s = this._storageService.getJWT();
    return HasStringValue(s);
   
  }
}

// moduleServiceName: string;
// modelName: string;
// requestMappingString?: string;
// urlPara?: string;
// operateAutomatically?: boolean;
class HttpCallParameter {
  moduleServiceName: string = '';
  modelName: string = '';
  requestMappingString?: string = '';
  urlPara?: string;
  captchaResp?: string;

  // 是否前端自动访问后端的操作，缺省值为不是，相当于假定访问服务器数据的操作都是用户主动的操作，
  // 前端保存访问的时间，作为用户在线的标志，该标志影响替匿名用户保存的数据。
  // 登录的用户其使用数据在登出时自动删除，如果没有主动登出，则在下次登录时主动删除？
  // 每次登录时从服务器获取相关的需要本地暂存的数据？
  // 周期性（指定分钟数）更新登录用户被修改过的本地保存数据？
  operateAutomatically?: boolean;

  // contextUrl: string = '';            // https://[host]:[port]/[moduleServiceName]/[contextUrl] TODO: 应该从服务器获取业务模型所在的module service name？
  enableErrorMsg?: boolean;
  pageData?: boolean;
  singleData?: boolean;
  listData?: boolean;
  static isValid(para: HttpCallParameter): boolean {
    return !(!para.moduleServiceName || !para.modelName);
  }
}
export class PostCallParameter extends HttpCallParameter {
  para?: any;
}

export class GetCallParameter extends HttpCallParameter {}

export class DeleteCallParameter extends HttpCallParameter {}
