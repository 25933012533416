import { inject, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private storageService = inject(StorageService);

  public isLoggedIn(): boolean {
    const jwt = this.storageService.getJWT();
    if (jwt) {
      const decodedJwt = jwtDecode(jwt);
      const isExpired = (decodedJwt?.exp || 0) > new Date().getTime();
      return !isExpired;
    }
    return false;
  }
}
