import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { tap } from 'rxjs';
import { PagingData } from '../models';
import { Product } from '../models/catalog.model';
import { ProductService } from '../services';

export interface ProductInitState {
  isLoading: boolean;
  hasLoadingError: boolean;
  products: Product[];
  productTotalRecords: number;
}
export const initState: ProductInitState = {
  isLoading: true,
  hasLoadingError: false,
  products: [],
  productTotalRecords: 0,
};

export const HomeProductStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initState),

  withMethods((store, productSrv = inject(ProductService)) => ({
    setIsLoading() {
      patchState(store, { isLoading: true, hasLoadingError: false });
    },
    loadSuccess() {
      patchState(store, { isLoading: false, hasLoadingError: false });
    },
    loadFailed() {
      patchState(store, { isLoading: false, hasLoadingError: true });
    },
    loadProducts(pageIndex: number, pageSize: number = 32) {
      productSrv
        .getProducts('', '', pageIndex, pageSize)
        .pipe(tap(() => patchState(store, { isLoading: true })))
        .subscribe(
          (response: PagingData<Product>) => {
            if (response && response.dataList && response.dataList.length > 0) {
              patchState(store, {
                productTotalRecords: response.totalRecords,
              });
              if (pageIndex != 0) {
                patchState(store, {
                  isLoading: false,
                  products: store.products().concat(response.dataList),
                });
              } else {
                patchState(store, {
                  isLoading: false,
                  products: response.dataList,
                });
              }
            } else {
              patchState(store, {
                isLoading: false,
              });
            }
          },
          (err) => {
            console.log(err);
            patchState(store, {
              isLoading: false,
              hasLoadingError: true,
              products: [],
              productTotalRecords: 0,
            });
          },
        );
    },
  })),
);
