import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavIndicatorService {
  private showNavIndicator: boolean = false;

  public enableNavIndicator() {
    this.showNavIndicator = true;
  }

  public disableNavIndicator() {
    this.showNavIndicator = false;
  }

  public getNavIndicator(): boolean {
    return this.showNavIndicator;
  }
}
