export const PRODUCTSORT = {
  RELEVANTLABEL: 'Most Relevant',
  PRICELABEL: 'Price Low to High',
  PRICEDESCLABEL: 'Price High to Low',
  RELEVANT: 'relevant',
  PRICEASC: 'curPrice.amount asc',
  PRICEDESC: 'curPrice.amount desc',
};
export const MAX_PRODUCT_LOAD_LIMIT: number = 32;
export const MAX_PRODUCT_LOADING_LIMIT: number = 16; // default is 16
export const MAX_AUTOCOMPLETE_ITEMS_ON_SEARCH: number = 8;
