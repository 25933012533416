import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PolarJService } from './polarj.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private _polarjService: PolarJService = inject(PolarJService);
  searchCategoryLabels(searchKey: string): Observable<Array<string>> {
    return this._polarjService.getCall({
      moduleServiceName: 'catalog',
      modelName: 'amcategorys',
      requestMappingString: 'labels/' + searchKey,
      listData: true,
    });
  }

  getAllCategories(): Observable<Array<string>> {
    return this._polarjService.getCall({
      moduleServiceName: 'catalog',
      modelName: 'amcategorys',
      requestMappingString: 'labels/ALL',
      listData: true,
    });
  }
}
