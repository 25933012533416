import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MansoryService {
  public onPageLoad$: Subject<Event> = new Subject<Event>();
  public onPageResize$: Subject<Event> = new Subject<Event>();
}
