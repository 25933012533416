import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { AmmDataLabelValue } from '../../components.global';
import { CommonService } from '../services/common.service';

export interface CommonStoreInitState {
  allCategories: AmmDataLabelValue[];
}

export const commonStoreInitState: CommonStoreInitState = {
  allCategories: [],
};

export const CommonStore = signalStore(
  { providedIn: 'root' },
  withState(commonStoreInitState),
  withMethods((store, commonSrv = inject(CommonService)) => ({
    loadAllCategory() {
      commonSrv.getAllCategories().subscribe((cates: string[]) => {
        if (cates) {
          const formattedCate = cates.map((c) => {
            const category = c.split('|');
            return <AmmDataLabelValue>{
              label: category[1],
              value: category[0],
            };
          });

          patchState(store, {
            allCategories: formattedCate,
          });
        }
      });
    },
  })),
);
