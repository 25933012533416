import { MODULE_SERVICE_NAME } from '../app/amm-share/constants';
import { Environment } from '../app/amm-share/models';

const serviceConfig = {
  protocal: 'https://',
  host: 'shoppal.com',
  port: '',
};
export const environment: Environment = {
  production: true,
  backendHost: 'https://shoppal.com',
  enableAnonymous: true,
  moduleService: {
    [MODULE_SERVICE_NAME.RBAC]: {
      ...serviceConfig,
    },
    [MODULE_SERVICE_NAME.CATALOG]: {
      ...serviceConfig,
    },
    [MODULE_SERVICE_NAME.SHOPPINGCART]: {
      ...serviceConfig,
    },
    [MODULE_SERVICE_NAME.MERCHANT]: {
      ...serviceConfig,
    },
    [MODULE_SERVICE_NAME.BMS]: {
      ...serviceConfig,
    },
    [MODULE_SERVICE_NAME.CRM]: {
      ...serviceConfig,
    },
    [MODULE_SERVICE_NAME.GLOBALDATA]: {
      ...serviceConfig,
    },
  },
};
