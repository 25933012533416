import { inject, Injectable } from '@angular/core';
import { AuthConfig, OAuthService, ReceivedTokens } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';
import { ShoppingInfoStore } from '../stores';
import { RbacService } from './rbac.service';
import { StorageService } from './storage.service';

export interface UserInfo {
  info: {
    sub: string;
    email: string;
    given_name: string;
    family_name: string;
    picture: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
  gmail = 'https://gmail.googleapis.com';
  token: string = '';
  userProfileSubject = new Subject<UserInfo>();
  authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: 'https://accounts.google.com',

    // strict discovery document disallows urls which not start with issuers url
    strictDiscoveryDocumentValidation: false,

    // URL of the SPA to redirect the user to after login
    redirectUri: '',
    logoutUrl: '',

    clientId:
      '360412492168-rnrkem7cua7ft7u1nf5isn96jp5ibbvj.apps.googleusercontent.com',

    scope: 'openid profile email',
    showDebugInformation: true,
  };
  private _storageService = inject(StorageService);
  private shoppingInfoStore = inject(ShoppingInfoStore);
  constructor(
    private readonly oAuthService: OAuthService,
    private _rbacService: RbacService,
  ) {}

  public initGoogleAPI() {
    this.authCodeFlowConfig.redirectUri = window?.location.origin;
    this.authCodeFlowConfig.logoutUrl = window?.location.origin;

    // confiure oauth2 service
    this.oAuthService.configure(this.authCodeFlowConfig);
    // manually configure a logout url, because googles discovery document does not provide it
    this.oAuthService.logoutUrl = 'https://www.google.com/accounts/Logout';

    this.oAuthService.loadDiscoveryDocumentAndTryLogin({
      onTokenReceived: (receivedTokens: ReceivedTokens) => {
        console.log('receivedTokens');
        console.log(receivedTokens);
        this._rbacService
          .googleLogin(receivedTokens.idToken)
          .subscribe((user) => {
            if (user) {
              this._storageService.setLocalItem(
                'buyerInfo',
                JSON.stringify(user),
              );
            }
            this.shoppingInfoStore.loadAllFavorites();
            this.shoppingInfoStore.loadShoppingCarts();
            // this.ammDialogService.loginDialogRef.close();
          });
      },
      preventClearHashAfterLogin: false,
    });
  }

  signin() {
    this.oAuthService.initCodeFlow();
  }

  signout() {
    //  this is to logout google from your borwser, should never used
    this.oAuthService.revokeTokenAndLogout();
    this.oAuthService.logOut();
  }
}
