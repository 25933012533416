import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProductFilter } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ProductFilterService {
  public searchKey$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchFilter$: Subject<ProductFilter | undefined> = new Subject<
    ProductFilter | undefined
  >();

  public clearSearchFilter$: Subject<boolean> = new Subject<boolean>();
}
