import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingData, ShoppingCartPayload } from '../models';
import {
  AmmFavoriteBackend,
  AmmShoppingCartBackEnd,
} from '../models/catalog.model';
import { MODULE_SERVICE_NAME } from './../constants/app.constant';
import { PolarJService } from './polarj.service';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private _polarjService: PolarJService = inject(PolarJService);
  updateShoppingCart(shoppingCarts: ShoppingCartPayload): Observable<any> {
    return this._polarjService.postCall({
      moduleServiceName: MODULE_SERVICE_NAME.SHOPPINGCART,
      modelName: 'thecart',
      requestMappingString: 'updateCart',
      para: shoppingCarts,
      pageData: true,
    });
  }
  //TODO: need to passing in the paging filter

  getShoppingCart(): Observable<PagingData<AmmShoppingCartBackEnd>> {
    return this._polarjService.getCall({
      moduleServiceName: MODULE_SERVICE_NAME.SHOPPINGCART,
      modelName: 'thecart',
      pageData: true,
    });
  }

  updateFavorites(
    favorites: ShoppingCartPayload,
  ): Observable<PagingData<boolean>> {
    return this._polarjService.postCall({
      moduleServiceName: MODULE_SERVICE_NAME.SHOPPINGCART,
      modelName: 'amfavourites',
      requestMappingString: 'batchUpdate',
      para: favorites,
    });
  }
  //TODO: need to passing in the paging filter
  getFavorites(): Observable<PagingData<AmmFavoriteBackend>> {
    return this._polarjService.getCall({
      moduleServiceName: MODULE_SERVICE_NAME.SHOPPINGCART,
      modelName: 'amfavourites',
      requestMappingString: 'merch',
      pageData: true,
    });
  }
}
