export class EnumValuesForSearching {
  fieldName?: string;
  values?: string;
}

export class RangeValueForSearching {
  fieldName?: string;
  fromValue?: string;
  toValue?: string;
}

export class BaseModel {
  constructor(entityName?: string) {
    this.entityName = entityName;
  }

  id?: number;
  tempId?: number; // will generated automatically locally to distinguish the new entity.
  curPageSize?: number;
  pageIndex?: number;
  sortField?: string;
  sortDesc?: boolean; // DESC(from big to small) or ASC(from small to big)

  // 保存模糊搜索的输入值，
  smartSearchText?: string;

  // urlParams发送到后端去的urlParams
  urlParams?: string;
  urlPath?: string;

  entityName?: string;
  rangeValues?: RangeValueForSearching[];
  eanumEnumValueForSearchings?: EnumValuesForSearching[];
  relatedId?: number;

  [key: string]: any;
  // 上传的数据中该列表中的数据为空，可以在修改时清除数据
  valueRemovedFields?: Array<string>;

  static generateSearchCriteria(
    modelName: string,
    pageIndex?: number,
    pageSize?: number,
    defaultPageSize?: number
  ): BaseModel {
    const cri = new BaseModel(modelName);
    cri.pageIndex = pageIndex && pageIndex >= 0 ? pageIndex : 0;
    cri.curPageSize = pageSize && pageSize > 0 ? pageSize : defaultPageSize;
    return cri;
  }

  static generateModel(modelName?: string, entity?: BaseModel): BaseModel {
    if (entity) {
      entity.entityName = modelName;
      return entity;
    }
    const bm = new BaseModel(modelName);
    return bm;
  }
}
export class Attachment extends BaseModel {
  uploadTime?: Date;
  description?: string;
  uri?: string;
  fileInfo?: FileInfo;
  isPrivate?: boolean;

  static getImageTrueURI(attachment?: Attachment): string {
    if (attachment == null) {
      return '';
    }
    if (attachment?.uri) {
      return attachment.uri!;
    }
    return attachment.fileInfo?.hashFileName || '';
  }

  static getImagesTrueUri(attachments?: Array<Attachment>): Array<string> {
    if (attachments && attachments.length > 0) {
      return [];
    }
    let ss = new Array<string>();
    attachments?.forEach((attachment) => {
      let s = Attachment.getImageTrueURI(attachment);
      if (s) {
        ss.push(s);
      }
    });
    return ss;
  }
  static getUriFromFileInfo(att?: Attachment): Attachment | undefined {
    if (att?.fileInfo?.content) {
      att.uri = att?.fileInfo?.content;
      return att;
    }
    return att;
  }

  static convertToImageSrc(item: Attachment): string {
    if (item.fileInfo?.content != null) {
      return item.fileInfo?.content;
    } else if (item.fileInfo?.byteContent != null) {
      return (
        'data:image/' +
        (item.fileInfo?.fileType || 'jpeg') +
        ';base64,' +
        item.fileInfo?.byteContent
      );
    }
    return item.uri || '';
  }
}

export class FileInfo {
  fileType?: string;
  content?: string;
  byteContent?: any;
  fileName?: string;
  hashFileName?: string;
  blobContent?: File;
  fileSize?: number;
  imgWidth?: number;
  imgHeight?: number;
}

// 对应后端的com.polarj.common.web.model.ClientRequest<T>
export class ClientRequest {
  nonceToken?: string;
  data: any;
  captchaResp?: string;
}

// 对应后端的com.polarj.common.StatusInfo
export class StatusInfo {
  referenceNo?: string;
  error?: boolean;
  code?: string;
  desc?: string;
}

// 对应后端的com.polarj.common.web.model.ServerResponse<T>
export class ServerResponse {
  nonceToken?: string;
  totalRecords?: number;
  totalPages?: number;
  currentPageIndex?: number;
  pageSize?: number;
  dataList?: Array<any>;
  statusList?: Array<StatusInfo>;
  groupCountsMap?:any;

  addErrorMessage(errCode: string, errMsg: string): void {
    if (!this.statusList) {
      this.statusList = new Array<StatusInfo>();
    }
    const statInfo = new StatusInfo();
    statInfo.error = true;
    statInfo.desc = errMsg;
    statInfo.code = errCode;
    this.statusList.push(statInfo);
  }
}
export interface HttpUrlPart {
  moduleServiceName: string;
  modelName: string;
  requestMappingString?: string;
  urlPara?: string;
  operateAutomatically?: boolean;
  captchaResp?: string;
}
export class PagingData<T> {
  totalRecords?: number;
  totalPages?: number;
  currentPageIndex?: number; // 从0!!!开始
  pageSize?: number;
  dataList?: Array<T>;
  groupCountsMap?:any;
}

export class SearchFilter {
  criteria?: BaseModel;
  smartSearchText?: string | null;
  filters?: Array<CustomFilter>;
}

export class MerchSearchFilter {
  smartSearchText?: string | null;
  hasCoupon?: boolean;
  hasDiscount?: boolean;
  freeShipping?: boolean;
  mainVariant?: number;
  rangeValues?: Array<RangeValueForSearching>;
  enumValuesForSearching?: Array<EnumValuesForSearching>;
  category?: { id: string };
  shopCode?: string;
}

export class CustomFilter {
  filterName?: string; // fieldName
  subFieldName?: string; // fieldName 对应业务模型的 fieldName
  valueFrom?: string; // 如果有多个值， 则创建多个相同的fieldName
  valueTo?: string;
}

export class SelectiveItem {
  value?: string;
  label?: string;
  checked?: boolean;
  // 数据中保存的是业务模型其他属性的缺省值
  // 应用场景举例：
  // 1. 一个被管理的服务内容会有固定收费
  // 2. 创建收据时引入该服务内容，但是收费的数据可以被修改
  defaultValue?: object = undefined;
}

export class SortType {
  fieldName?: string;
  desc?: boolean; // desc: 是否为降序
}

/**
 * Amm Key value
 */
export interface AmmKeyValue<T> {
  [key: string]: T;
}
