import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PolarJService } from './polarj.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private _polarjService: PolarJService = inject(PolarJService);

  public getOrderByPoNumber(poNumber: string): Observable<void> {
    //TODO: need to hook up the order API
    return of();
  }
  public getOrders(
    searchKey: string,
    pagesize: number,
    pageIndex: number,
  ): Observable<void> {
    //TODO: need to hook up the order API

    return of();
  }
}
