import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { tap } from 'rxjs';
import { AmmDataLabelValue } from '../../components.global';
import { AmmValueCount, PagingData, ProductFilter } from '../models';
import { Product } from '../models/catalog.model';
import { ProductService } from '../services';
import { CommonStore } from './common.store';

export interface ProductFilterInitialState {
  showPanelBtn: boolean;
  showPanel: boolean;
  searchKeyWord: string;
  isLoading: boolean;
  hasLoadingError: boolean;
  products: Product[];
  variantSelection: Map<string, string>;
  filteredProducts: Product[];
  filteredCategoryAndCounts: AmmDataLabelValue[];
  filteredTotalRecords: number;
  productTotalRecords: number;
  filteredMinPrice: number;
  filteredMaxPrice: number;
  selectedMinPrice: number;
  selectedMaxPrice: number;
  previousSearchKey: string;
}

export const searchProductInitState: ProductFilterInitialState = {
  showPanelBtn: false,
  showPanel: false,
  searchKeyWord: '',
  isLoading: true,
  hasLoadingError: false,
  products: [],
  variantSelection: new Map<string, string>(),
  filteredProducts: [],
  filteredCategoryAndCounts: [],
  filteredTotalRecords: 0,
  productTotalRecords: 0,
  filteredMinPrice: 0,
  filteredMaxPrice: 100,
  selectedMinPrice: 0,
  selectedMaxPrice: 100,
  previousSearchKey: '',
};

export const SearchProductStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(searchProductInitState),
  withComputed((store) => ({
    hasReachedFilteredProductBottom: computed(() => {
      return store.filteredProducts().length === store.filteredTotalRecords();
    }),
    hasReachedExporeProductBottom: computed(() => {
      return store.products().length === store.productTotalRecords();
    }),
    selectedCategories: computed(() => {
      let categoryId = '';
      store.filteredCategoryAndCounts().forEach((x) => {
        if (x.selected) {
          categoryId += x.value + ',';
        }
      });
      return categoryId.slice(0, -1);
    }),
    selectedCategoriesCount: computed(() => {
      return store
        .filteredCategoryAndCounts()
        .filter((x) => x.selected === true).length;
    }),
  })),
  withMethods(
    (
      store,
      productSrv = inject(ProductService),
      commonStore = inject(CommonStore),
    ) => ({
      showFilterPanelBtn() {
        patchState(store, { showPanelBtn: true });
      },
      hideFilterPanelBtn() {
        patchState(store, { showPanelBtn: false });
      },
      showFilterPanel() {
        patchState(store, { showPanel: true });
      },
      hideFilterPanel() {
        patchState(store, { showPanel: false });
      },
      toggleFilterPanel() {
        patchState(store, { showPanel: !store.showPanel() });
      },
      setSearchKey(term: string) {
        patchState(store, { searchKeyWord: term });
      },
      setIsLoading() {
        patchState(store, { isLoading: true, hasLoadingError: false });
      },
      loadSuccess() {
        patchState(store, { isLoading: false, hasLoadingError: false });
      },
      loadFailed() {
        patchState(store, { isLoading: false, hasLoadingError: true });
      },
      loadFilteredProducts(
        searchKey: string,
        pageIndex: number,
        pageSize: number = 32,
        productFilter: ProductFilter | undefined,
      ) {
        let filterConditions = '';
        if (productFilter) {
          filterConditions += productFilter.categories
            ? `&categories=${productFilter.categories}`
            : '';
          filterConditions += productFilter.priceFrom
            ? `&priceFrom=${productFilter.priceFrom}`
            : '';
          filterConditions += productFilter.priceTo
            ? `&priceTo=${productFilter.priceTo}`
            : '';

          filterConditions += productFilter.sorts
            ? `&sorts=${productFilter.sorts}`
            : '';
        }
        productSrv
          .getProducts(searchKey, filterConditions, pageIndex, pageSize)
          .pipe(tap(() => patchState(store, { isLoading: true })))
          .subscribe(
            (response: PagingData<Product>) => {
              if (response && response.groupCountsMap) {
                const stats = response.groupCountsMap;
                const categoryWithCounts = stats[
                  'category.id'
                ] as AmmValueCount[];

                if (categoryWithCounts) {
                  let cateToLoad = categoryWithCounts.map((x) => {
                    const label =
                      commonStore
                        .allCategories()
                        .find((c) => c.value === x.value)?.label || '';
                    return <AmmDataLabelValue>{
                      label: label,
                      value: x.value,
                      count: x.count,
                      selected: false,
                    };
                  });
                  cateToLoad = cateToLoad.filter(
                    (c) => (c.label?.length || 0) > 0,
                  );
                  patchState(store, { filteredCategoryAndCounts: cateToLoad });
                }
                const curPrice = stats['curPrice.amount'] as AmmValueCount[];
                if (curPrice && curPrice.length > 0) {
                  const minPrice =
                    curPrice.find((x) => x.value === 'minPrice')?.count || 0;
                  const maxPrice =
                    curPrice.find((x) => x.value === 'maxPrice')?.count || 100;
                  if (store.previousSearchKey() === searchKey) {
                  } else {
                    // If it is the same search key first time to come,
                    // we should update the upper and lower bound of the
                    // price range and update the previous search key
                    patchState(store, {
                      previousSearchKey: searchKey,
                      filteredMaxPrice: maxPrice,
                      filteredMinPrice: minPrice,
                      selectedMaxPrice: maxPrice,
                      selectedMinPrice: minPrice,
                    });
                  }
                }
              }
              if (
                response &&
                response.dataList &&
                response.dataList.length > 0
              ) {
                patchState(store, {
                  filteredTotalRecords: response.totalRecords,
                });
                if (pageIndex != 0) {
                  patchState(store, {
                    isLoading: false,
                    filteredProducts: store
                      .filteredProducts()
                      .concat(response.dataList),
                  });
                } else {
                  patchState(store, {
                    isLoading: false,
                    filteredProducts: response.dataList,
                  });
                }
              } else {
                patchState(store, {
                  filteredProducts: [],
                  filteredTotalRecords: 0,
                  isLoading: false,
                });
              }
            },
            (err) => {
              console.log(err);
              patchState(store, {
                isLoading: false,
                hasLoadingError: true,
                filteredProducts: [],
                filteredTotalRecords: 0,
              });
            },
          );
      },
      loadProducts(pageIndex: number, pageSize: number = 32) {
        productSrv
          .getProducts('', '', pageIndex, pageSize)
          .pipe(tap(() => patchState(store, { isLoading: true })))
          .subscribe(
            (response: PagingData<Product>) => {
              if (
                response &&
                response.dataList &&
                response.dataList.length > 0
              ) {
                patchState(store, {
                  productTotalRecords: response.totalRecords,
                });
                if (pageIndex != 0) {
                  patchState(store, {
                    isLoading: false,
                    products: store.products().concat(response.dataList),
                  });
                } else {
                  patchState(store, {
                    isLoading: false,
                    products: response.dataList,
                  });
                }
              } else {
                patchState(store, {
                  isLoading: false,
                });
              }
            },
            (err) => {
              console.log(err);
              patchState(store, {
                isLoading: false,
                hasLoadingError: true,
                products: [],
                productTotalRecords: 0,
              });
            },
          );
      },
      setVariantSelection(type: string, value: string) {
        patchState(store, {
          variantSelection: store.variantSelection().set(type, value),
        });
      },
      resetFilteredProducts() {
        patchState(store, { filteredProducts: [], filteredTotalRecords: 0 });
      },
      resetExploreProducts() {
        patchState(store, { products: [], productTotalRecords: 0 });
      },
      toggleCategorySelection(cate: AmmDataLabelValue, selection: boolean) {
        patchState(store, {
          filteredCategoryAndCounts: store
            .filteredCategoryAndCounts()
            .map((x) =>
              x.value === cate.value ? { ...x, selected: selection } : x,
            ),
        });
      },
      resetCatorySelecttion() {
        const newCates = store.filteredCategoryAndCounts().map(
          (x) =>
            <AmmDataLabelValue>{
              label: x.label,
              value: x.value,
              count: x.count,
              selected: false,
            },
        );
        patchState(store, {
          filteredCategoryAndCounts: newCates,
        });
      },
      setMaxSelectedPrice(price: number) {
        patchState(store, { selectedMaxPrice: price });
      },
      setMinSelectedPrice(price: number) {
        patchState(store, { selectedMinPrice: price });
      },
    }),
  ),
);
