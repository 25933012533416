import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariantSelectionService {
  /**
   * Represents the user variant selection
   * Key: variant type
   * Value: variant value
   */
  public variantSelection = new Map<string, string>();

  /**
   * Keep record of the variant order to make sure that the
   * variant selection object key generated in the correct order
   * Key: variant type
   * Value: position
   * !this position is maintained by the back end for details please check
   * !https://github.com/American-Mall-Company/catalog/commit/302a5e5038e515836225663cb364061fb35bff9c#diff-7a9805e2bcd94e3c653d2fa7c4fa9a709aa9c2bc6fd598dba488b05883a8e1ab
   */
  public variantTypeOrder = new Map<string, number>();

  public variantOnSelection$: Subject<boolean> = new Subject<boolean>();

  public getFormattedSelection() {
    let result = '';
    this.variantTypeOrder.forEach((orderIdx, variantType) => {
      const selection = this.variantSelection.get(variantType);
      if (selection) {
        result += selection + '|';
      }
    });
    return result.substring(0, result.length - 1);
  }

  public getSelectionAsObject() {
    return Object.fromEntries(this.variantSelection);
  }

  public resetMaps() {
    this.variantTypeOrder.clear();
    this.variantSelection.clear();
  }
}
