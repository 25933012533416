/**
 * Constants that holds the business
 * endpoint request map
 */
export const endpointPath = {

  LOGIN: 'login',
  LOGOUT: 'logout',
  EMAIL_LOGIN: 'email-login',
  GOOGLE: 'google',
  SOCIALLOGIN:'socialLogin',
  REGISTER: 'register',                                  // 需要returnUrl参数，是前端的站点链接
  CONFIRM: 'confirm',                                    // 需要returnUrl参数，是前端的站点链接
  EMAIL_LOGIN_REQUEST: 'email-login-request',            // 需要returnUrl参数，是前端的站点链接
  RESET_PASSWORD: 'reset-password',
  VERIFY_CODE:'verify-code',
  RESEND_VERIFICATION: 'resend-verification',            // 需要returnUrl参数，是前端的站点链接
  CHANGE_PASSWORD: 'change-password',
  EXISTS:'exists',
  RESET_PASSWORD_REQUEST: 'reset-password-request',
}
