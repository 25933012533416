import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mockProducts } from '../mockData';
import { Product, ShippingOption, Store } from '../models/catalog.model';
import { ProductModel } from '../models/ProductModel';
import { PolarJService } from './polarj.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private _polarjService: PolarJService = inject(PolarJService);

  getAllMockedProducts(): Observable<ProductModel[] | undefined> {
    return of(mockProducts);
  }
  getProducts(
    searchKey: string = '',
    productFilter: string = '',
    currentPageIndex: number = 0,
    pageSize: number = 32,
  ) {
    return this._polarjService.postCall({
      moduleServiceName: 'catalog',
      modelName: 'ammerchandises',
      requestMappingString: 'quickSearchCombined',
      urlPara: `pageIndex=${currentPageIndex}&pageSize=${pageSize}${productFilter}`,
      para: searchKey,
      pageData: true,
    });
  }

  getStoreProducts(
    storeCode: string = '',
    currentPageIndex: number = 0,
    pageSize: number = 15,
  ) {
    return this._polarjService.postCall({
      moduleServiceName: 'catalog',
      modelName: 'ammerchandises',
      requestMappingString: 'quickSearchCombined',
      urlPara: `pageIndex=${currentPageIndex}&pageSize=${pageSize}&storeCode=${storeCode}`,
      pageData: true,
    });
  }

  getProductsByFamilyCode(familyCode: string): Observable<Product> {
    return this._polarjService.getCall({
      moduleServiceName: 'catalog',
      modelName: 'ammerchfamilys',
      requestMappingString: `detail/${familyCode}`,
      singleData: true,
    });
  }
  getAutoComplete(searchKey: string): Observable<Array<string>> {
    return this._polarjService.postCall({
      moduleServiceName: 'catalog',
      modelName: 'amkeywords',
      requestMappingString: 'searchByKey',
      para: searchKey,
      listData: true,
    });
  }

  getProductById(id: number): Observable<Product> {
    return this._polarjService.getCall({
      moduleServiceName: 'catalog',
      modelName: 'ammerchandises',
      requestMappingString: `${id}`,
      singleData: true,
    });
  }

  getStoreShippingOptions(storeCode: string): Observable<ShippingOption[]> {
    return this._polarjService.getCall({
      moduleServiceName: 'merchant',
      modelName: 'amstores',
      requestMappingString: `${storeCode}/shippingOption`,
    });
  }

  getStoreById(id: number): Observable<Store> {
    return this._polarjService.getCall({
      moduleServiceName: 'merchant',
      modelName: 'amstores',
      requestMappingString: `${id}`,
      singleData: true,
    });
  }
}
