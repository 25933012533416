import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  ItemOp,
  Orders,
  PagingData,
  ShopingCart,
  ShoppingCartPayload,
} from '../models';
import {
  AmmFavoriteBackend,
  AmmShoppingCartBackEnd,
} from '../models/catalog.model';
import { ShoppingCartService } from '../services';
import { Favorite } from './../models/favorite.interface';

type ShoppingInfo = {
  favorites: Favorite[];
  orders: Orders[];
  shoppingCarts: ShopingCart[];
};

const initialState: ShoppingInfo = {
  favorites: [],
  orders: [],
  shoppingCarts: [],
};
export const ShoppingInfoStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withComputed((shoppingInfo) => ({
    shoppingCartCount: computed(() => {
      const carts = shoppingInfo.shoppingCarts();
      if (carts.length > 0) {
        return carts
          .filter((x) => (x.code?.length || 0) > 0)
          .map((x) => x.qty)
          .reduce((prev, cur) => {
            return prev + cur;
          }, 0);
      }
      return 0;
    }),
    favoriteProducts: computed(() => {
      const result: Favorite[] = [];

      const favorites = shoppingInfo.favorites();
      const addFavorites = favorites.filter((x) => x.action === 'ADD');
      const removeFavorites = favorites.filter((x) => x.action === 'REMOVE');

      addFavorites.forEach((x) => {
        const isInRemoved =
          removeFavorites.findIndex((rf) => rf.merchCode === x.merchCode) != -1;
        if (!isInRemoved) {
          result.push(x);
        }
      });

      return result;
    }),
    favoriteCount: computed(() => {
      const favorites = shoppingInfo.favorites();
      const addFavorites = favorites.filter((x) => x.action === 'ADD');
      const removeFavorites = favorites.filter((x) => x.action === 'REMOVE');
      let counter = 0;

      addFavorites.forEach((af) => {
        const isRemoved =
          removeFavorites.findIndex((rf) => rf.merchCode === af.merchCode) ==
          -1;
        if (!isRemoved) {
          counter++;
        }
      });

      return counter;
    }),
  })),
  withMethods((store, shoppingService = inject(ShoppingCartService)) => ({
    loadAllFavorites() {
      shoppingService
        .getFavorites()
        .subscribe((response: PagingData<AmmFavoriteBackend>) => {
          if (response && response.dataList && response.dataList.length > 0) {
            const favorites = response.dataList;
            const favoritesToLoad = favorites.map(
              (x) =>
                <Favorite>{
                  merchCode: x.merchCode,
                  action: 'ADD',
                },
            );
            patchState(store, {
              favorites: favoritesToLoad,
            });
          }
        });
    },
    increaseFavorite(merchCode: string) {
      const existFavorite = store
        .favorites()
        .findIndex((x) => x.merchCode === merchCode && x.action === 'ADD');
      if (existFavorite == -1) {
        patchState(store, {
          favorites: [
            ...store.favorites(),
            { merchCode: merchCode, action: 'ADD' },
          ],
        });
      }
      const itemToSave = store
        .favorites()
        .find((x) => x.merchCode === merchCode && x.action == 'ADD');
      if (itemToSave) {
        this.saveSingleFavoriteToDB(itemToSave);
      }
    },
    decreaseFavorite(merchCode: string) {
      const existFavorite = store
        .favorites()
        .findIndex((x) => x.merchCode === merchCode);
      if (existFavorite == -1) {
        patchState(store, {
          favorites: [
            ...store.favorites(),
            { merchCode: merchCode, action: 'REMOVE' },
          ],
        });
      } else {
        const existAddFavorite = store
          .favorites()
          .findIndex((x) => x.merchCode === merchCode && x.action === 'ADD');
        if (existAddFavorite != -1) {
          patchState(store, {
            favorites: store
              .favorites()
              .map((x) =>
                x.merchCode == merchCode ? { ...x, action: 'REMOVE' } : x,
              ),
          });
        }
      }

      const itemToSave = store
        .favorites()
        .find((x) => x.merchCode === merchCode && x.action == 'REMOVE');
      if (itemToSave) {
        this.saveSingleFavoriteToDB(itemToSave);
      }
    },
    saveFavoriteToDB() {
      const curFavorites = store.favorites();
      const favoritesToDb = <ShoppingCartPayload>{};
      favoritesToDb.itemOps = curFavorites.map(
        (x) =>
          <ItemOp>{
            merchCode: x.merchCode,
            action: x.action,
          },
      );
      // In order to send http request, we have to subscribe
      shoppingService.updateFavorites(favoritesToDb).subscribe(() => {
        this.loadAllFavorites();
      });
    },
    saveSingleFavoriteToDB(favorite: Favorite) {
      const favoritesToDb = <ShoppingCartPayload>{};
      favoritesToDb.itemOps = [
        <ItemOp>{
          merchCode: favorite.merchCode,
          action: favorite.action,
        },
      ];

      // In order to send http request, we have to subscribe
      shoppingService.updateFavorites(favoritesToDb).subscribe(() => {
        this.loadAllFavorites();
      });
    },
    increaseShopingCart(merchCode: string) {
      const index = store
        .shoppingCarts()
        .findIndex((x) => x.code === merchCode);
      if (index == -1) {
        patchState(store, {
          shoppingCarts: [
            ...store.shoppingCarts(),
            { qty: 1, code: merchCode },
          ],
        });
      } else {
        patchState(store, (state) => ({
          shoppingCarts: state.shoppingCarts.map((x, idx) =>
            x.code === merchCode ? { ...x, qty: x.qty + 1 } : x,
          ),
        }));
      }
      const itemToSave = store
        .shoppingCarts()
        .find((s) => s.code === merchCode);
      this.saveSingleShoppingCartToDB(
        itemToSave?.code || merchCode,
        itemToSave?.qty || 0,
      );
    },
    decreaseShopingCart(merchCode: string) {
      const index = store
        .shoppingCarts()
        .findIndex((x) => x.code === merchCode);
      if (index != -1) {
        patchState(store, (state) => ({
          shoppingCarts: state.shoppingCarts.map((x, idx) =>
            idx == index ? { ...x, qty: Math.max(x.qty - 1, 0) } : x,
          ),
        }));
        const itemToSave = store
          .shoppingCarts()
          .find((s) => s.code === merchCode);
        this.saveSingleShoppingCartToDB(
          itemToSave?.code || merchCode,
          itemToSave?.qty || 0,
        );
      }
    },
    setVariantShoppingCart(qty: number, merchCode: string) {
      const index = store
        .shoppingCarts()
        .findIndex((x) => x.code === merchCode);
      if (index == -1) {
        patchState(store, {
          shoppingCarts: [
            ...store.shoppingCarts(),
            { qty: qty, code: merchCode },
          ],
        });
      } else {
        patchState(store, {
          shoppingCarts: store
            .shoppingCarts()
            .map((sc, idx) =>
              sc.code === merchCode ? { ...sc, qty: qty } : sc,
            ),
        });
      }
      const itemToSave = store
        .shoppingCarts()
        .find((s) => s.code === merchCode);
      this.saveSingleShoppingCartToDB(
        itemToSave?.code || merchCode,
        itemToSave?.qty || 0,
      );
    },
    saveShoppingCartsToDB() {
      const carts = store.shoppingCarts();
      const cartsToDb = <ShoppingCartPayload>{};

      cartsToDb.itemOps = carts.map(
        (x) =>
          <ItemOp>{
            merchCode: x.code,
            qty: x.qty,
          },
      );

      shoppingService.updateShoppingCart(cartsToDb).subscribe(() => {
        this.loadShoppingCarts();
      });
    },
    saveSingleShoppingCartToDB(merchCode: string, qty: number) {
      const cartsToDb = <ShoppingCartPayload>{};

      cartsToDb.itemOps = [
        <ItemOp>{
          merchCode: merchCode,
          qty: qty,
        },
      ];

      shoppingService.updateShoppingCart(cartsToDb).subscribe(() => {
        this.loadShoppingCarts();
      });
    },
    loadShoppingCarts() {
      shoppingService
        .getShoppingCart()
        .subscribe((response: PagingData<AmmShoppingCartBackEnd>) => {
          if (response && response.dataList && response.dataList.length > 0) {
            const itemsInDb = response?.dataList[0]?.items?.map(
              (x) =>
                <ShopingCart>{
                  code: x?.merchCode,
                  qty: x?.qty,
                },
            );
            patchState(store, {
              shoppingCarts: itemsInDb,
            });
          }
        });
    },
  })),
);
